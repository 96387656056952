interface Props {
  size: number;
  color: 'dark-blue' | 'white';
}

export function Cart({ size, color }: Props) {
  let colorHex;
  if (color === 'dark-blue') {
    colorHex = '#292b4e';
  } else if (color === 'white') {
    colorHex = '#fff';
  }

  return (
    <svg height={size} xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24">
      <path
        fill={colorHex}
        fillRule="evenodd"
        d="M23.9 4.1l-2.7 8.1c-.1.2-.2.4-.4.5H7.3l.6 2.7h11.8c.4 0 .7.3.7.7 0 .4-.3.7-.7.7H7.4c-.2 0-.3-.1-.4-.1l-.1-.1c-.1-.1-.2-.2-.2-.3l-3-12.9c-.1-.2-.2-.3-.3-.4-.3-.4-.7-.4-1.1-.4-.5 0-.8.2-1 .4.3-.4-.4.6-1.1 0-.2-.4-.3-.8.2-1.3S1.5 1 2.3 1c.9 0 1.5.3 2.1.8l.4.4c0 .1.1.2.2.4l2 8.7h13l2.2-6.6H7c-.4 0-.7-.3-.7-.7 0-.4.3-.7.7-.7h16.4c.4 0 .6.4.5.8zM9 18.6c1.2 0 2.2 1 2.2 2.2 0 1.2-1 2.2-2.2 2.2s-2.2-1-2.2-2.2c0-1.2 1-2.2 2.2-2.2zM9 22c.6 0 1.2-.5 1.2-1.2 0-.6-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2c.1.6.6 1.2 1.2 1.2zm9.2-3.4c1.2 0 2.2 1 2.2 2.2 0 1.2-1 2.2-2.2 2.2-1.2 0-2.2-1-2.2-2.2 0-1.2.9-2.2 2.2-2.2zm0 3.4c.6 0 1.2-.5 1.2-1.2 0-.6-.5-1.2-1.2-1.2-.6 0-1.2.5-1.2 1.2 0 .6.5 1.2 1.2 1.2z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
