import styles from './WebshopLink.module.scss';
import { Cart } from './../Icons/Cart';
import { ArrowAlt } from '../Icons/ArrowAlt';
import { SHOP_ONLINE_URL } from '../../config';

interface Props {
  small?: boolean;
}

export function WebshopLink({ small }: Props) {
  return (
    <a
      href={SHOP_ONLINE_URL}
      target="_blank"
      rel="noreferrer"
      className={`d-flex justify-content-center align-items-center ${styles.webshopLink} ${
        small ? styles.webshopLinkSmall : ''
      }`}
    >
      <Cart size={small ? 22 : 30} color="white" />
      <span className={styles.webshopLinkText}>Przejdź do sklepu online</span>
      <ArrowAlt size={10} color="white" />
    </a>
  );
}
