import { ReactChild } from 'react';
import { Link } from 'react-router-dom';
import styles from './NavTop.module.scss';
import logoImage from '../../assets/logo.png';

interface Props {
  children?: ReactChild;
}

export function NavTop({ children }: Props) {
  return (
    <div className={styles.navigation}>
      <div className={styles.topBar} />
      <div className={styles.mainBar}>
        <Link to="/" className={styles.logoContainer}>
          <img src={logoImage} alt="Leroy Merlin logo" width="209" height="88" />
        </Link>
        <div className={`d-flex ${styles.navContainer}`}>{children}</div>
      </div>
    </div>
  );
}
