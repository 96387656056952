import styles from './CatalogSelection.module.scss';
import { NavTop } from '../../components/NavTop/NavTop';
import { useContext } from 'react';
import { CatalogsContext } from '../../App';
import { ArrowAlt } from './../../components/Icons/ArrowAlt';
import { Link, Location, useLocation } from 'react-router-dom';
import { WebshopLink } from '../../components/WebshopLink/WebshopLink';
import { Cart } from '../../components/Icons/Cart';
import { SHOP_ONLINE_URL } from './../../config';

export function CatalogSelection() {
  const catalogList = useContext(CatalogsContext);

  const { search } = useLocation();

  if (!catalogList) {
    return null;
  }

  let mainCatalogList = catalogList.filter((catalog) => !!catalog.isMain);

  if (!mainCatalogList.length) {
    mainCatalogList = [catalogList[0]];
  }

  const remainingCatalogList = catalogList.filter((catalog) => !catalog.isMain);
  const isSingleMainCatalog = mainCatalogList.length === 1;

  const renderSingleMainCatalogLayout = () => {
    const mainCatalog = mainCatalogList[0];
    if (!mainCatalog.image) {
      return null;
    }

    return (
      <div className={`col-12 col-lg-5 ${styles.leftColSingleMain}`}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className={styles.mainCatalogImageWrapper}>
            <img
              src={mainCatalog.image.medium}
              alt={`Okładka - ${mainCatalog.name}`}
              width={mainCatalog.pagesSize.width}
              height={mainCatalog.pagesSize.height}
            />
          </div>
          <SeeCatalogLink
            to={`/${mainCatalog.url}`}
            externalRedirectUrl={mainCatalog.externalRedirectUrl}
            text={mainCatalog.listButtonText}
          />
        </div>
        <hr className={`desktop-only ${styles.ySeparator}`} />
      </div>
    );
  };

  const renderMultipleMainCatalogLayout = () => {
    const alignLeft = mainCatalogList.length % 3 === 1;
    return (
      <div
        className={`w-100 d-flex flex-wrap ${!alignLeft ? 'justify-content-center' : ''} ${
          styles.multiMainCatalogWrapper
        } ${styles.xSeparator} ${styles.mobileXSeparator}`}
      >
        {mainCatalogList.map((mainCatalog) => {
          if (!mainCatalog.image) {
            return null;
          }
          return (
            <div className={`col-12 col-md-6 col-lg-4 ${styles.colMultiMain}`} key={mainCatalog.url}>
              <div className="d-flex flex-column justify-content-center align-items-center w-100">
                <div className={`${styles.mainCatalogImageWrapper} ${styles.multiMainCatalogImageWrapper}`}>
                  <img
                    src={mainCatalog.image.medium}
                    alt={`Okładka - ${mainCatalog.name}`}
                    width={mainCatalog.pagesSize.width}
                    height={mainCatalog.pagesSize.height}
                  />
                </div>
                <SeeCatalogLink
                  to={`/${mainCatalog.url}`}
                  externalRedirectUrl={mainCatalog.externalRedirectUrl}
                  text={mainCatalog.listButtonText}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <NavTop>
        <div className="d-flex justify-content-end w-100">
          <div className={`d-flex align-items-center ${styles.navWebshopLinkFull}`}>
            <WebshopLink small />
          </div>
          <a className={styles.navWebshopLinkIcon} href={SHOP_ONLINE_URL} target="_blank" rel="noreferrer">
            <Cart color="white" size={28} />
          </a>
        </div>
      </NavTop>
      <div className="container">
        {!catalogList.length ? (
          <div>Brak dostępnych katalogów</div>
        ) : (
          <>
            <div className={`d-flex flex-wrap ${styles.catalogListing}`}>
              {isSingleMainCatalog ? renderSingleMainCatalogLayout() : renderMultipleMainCatalogLayout()}

              {remainingCatalogList.length ? (
                <div className={`col-12 ${isSingleMainCatalog ? `col-lg-7 ${styles.rightCol}` : ''}`}>
                  {isSingleMainCatalog ? (
                    <hr className={`mobile-only ${styles.xSeparator} ${styles.mobileXSeparator}`} />
                  ) : null}
                  <div className="d-flex flex-wrap">
                    {remainingCatalogList.map((catalog) => {
                      return (
                        <div key={catalog.url} className="col-6 col-md-4 d-flex justify-content-center">
                          {catalog.image ? (
                            <div className={`d-flex flex-column align-items-center ${styles.catalogListItem}`}>
                              <div className={styles.catalogListImageWrapper}>
                                <img
                                  src={catalog.image.medium}
                                  alt={`Okładka - ${catalog.name}`}
                                  width={catalog.pagesSize.width}
                                  height={catalog.pagesSize.height}
                                />
                              </div>
                              <SeeCatalogLink
                                to={`/${catalog.url}${search}`}
                                externalRedirectUrl={catalog.externalRedirectUrl}
                                alternative
                                text={catalog.listButtonText}
                              />
                            </div>
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>

            <hr className={`${styles.xSeparator} ${styles.sectionSeparator}`} />

            <div className={`d-flex justify-content-center ${styles.webshopLinkSection}`}>
              <WebshopLink />
            </div>
          </>
        )}
      </div>
    </>
  );
}

interface LinkProps {
  to: string | Location;
  alternative?: boolean;
  text: string | undefined;
  externalRedirectUrl?: string;
}

function SeeCatalogLink({ to, externalRedirectUrl, alternative, text }: LinkProps) {
  const linkText = text || 'Zobacz katalog';

  const classNames = `d-flex justify-content-center align-items-center ${styles.seeCatalogLink} ${
    alternative ? styles.linkAlt : styles.linkPrimary
  }`;

  const textComponent = <span>{linkText}</span>;
  const arrowComponent = (
    <ArrowAlt className={styles.seeCatalogLinkArrow} size={10} color={alternative ? 'green' : 'white'} />
  );

  if (externalRedirectUrl) {
    return (
      <a className={classNames} href={externalRedirectUrl}>
        {textComponent}
        {arrowComponent}
      </a>
    );
  }

  return (
    <Link to={to} className={classNames}>
      {textComponent}
      {arrowComponent}
    </Link>
  );
}
