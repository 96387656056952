import { PREVIEW_MODE_SEARCH_PARAM } from '../config';
import { API$RawResponse } from './types';

export default function fetcher<T>(resource: RequestInfo, init?: RequestInit, parser?: (data) => T) {
  // if url has query param ?preview=1 then append ?preview=1 to endpoint addresses
  // to enable preview mode
  // that shows unpublished catalogs etc
  let previewMode = false;
  if (window.URLSearchParams) {
    const searchParams = new URLSearchParams(window.location.search);
    previewMode = searchParams.get(PREVIEW_MODE_SEARCH_PARAM) === '1';
  }

  // preview mode for API
  if (previewMode && typeof resource === 'string') {
    resource = `${resource}?preview=1`;
  }

  return fetch(resource, init).then(async (res) => {
    const parsedRes: API$RawResponse = await res.json();
    if (parsedRes.status === 'ERROR') {
      console.warn('Error during request:', parsedRes.message);
      return Promise.reject(parsedRes.message);
    }

    if (typeof parser === 'function') {
      return parser(parsedRes.content);
    }

    return parsedRes.content;
  });
}
