import { createContext, lazy, Suspense } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Loader } from './components/Loader/Loader';
import { useCatalogsMeta } from './hooks/useCatalogsMeta';
import { CatalogSelection } from './screens/CatalogSelection/CatalogSelection';
import { CatalogMeta } from './types';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { META_DESCRIPTION_DEFAULT, META_TITLE_DEFAULT } from './config';

const Catalog = lazy(() => import(/* webpackPrefetch: true */ './screens/Catalog/Catalog'));

export const CatalogsContext = createContext<CatalogMeta[] | undefined>(undefined);

function App() {
  const { catalogsData, isLoading, error } = useCatalogsMeta();
  const { search } = useLocation();

  if (error) {
    return <div>Wystąpił błąd!</div>;
  }

  if (isLoading) {
    return <Loader fullPage />;
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>{META_TITLE_DEFAULT}</title>
        <meta name="description" content={META_DESCRIPTION_DEFAULT} />
      </Helmet>

      <CatalogsContext.Provider value={catalogsData}>
        <Suspense fallback={<Loader fullPage />}>
          <Routes>
            <Route path="/" element={<CatalogSelection />} />
            <Route path=":catalogUrl" element={<Navigate to={`strona/1${search}`} replace />} />
            <Route path=":catalogUrl/strona/:pageNumber" element={<Catalog />} />
            <Route path="*" element={<div>Strona nie została znaleziona</div>} />
          </Routes>
        </Suspense>
      </CatalogsContext.Provider>
    </HelmetProvider>
  );
}

export default App;
