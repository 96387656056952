export const SHOP_ONLINE_URL = 'https://www.leroymerlin.pl/';

// check public/api-config.js file where API URL is added to window
export const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? `https://cms.${window.location.hostname.replace('www.', '')}/api`
    : 'https://cms.test.katalog.leroymerlin.pl/api';

export const PREVIEW_MODE_SEARCH_PARAM = 'preview';

export const META_TITLE_DEFAULT = 'Katalog Leroy Merlin';
export const META_DESCRIPTION_DEFAULT =
  'Zainspiruj się nowymi katalogami: Ogród, Ogrodzenia i Fachowcy. Znajdziesz w nich aktualne oferty produktów oraz ciekawe aranżacje. Zapraszamy!';
