interface Props {
  size: number | string;
  color: 'dark-blue' | 'dark-blue-alt' | 'white' | 'green' | 'black-3e';
  strokeWidth?: number;
  className?: string;
}

export function ArrowAlt({ size, color, strokeWidth, className }: Props) {
  let colorHex;
  if (color === 'dark-blue') {
    colorHex = '#292b4e';
  } else if (color === 'dark-blue-alt') {
    colorHex = '#00263A';
  } else if (color === 'white') {
    colorHex = '#fff';
  } else if (color === 'green') {
    colorHex = '#78be20';
  } else if (color === 'black-3e') {
    colorHex = '#3e3e3e';
  }

  return (
    <svg className={className || ''} height={size} viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L11 11L1 21" stroke={colorHex} strokeWidth={strokeWidth || 2} strokeLinecap="round" />
    </svg>
  );
}
