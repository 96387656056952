import useSWRImmutable from 'swr/immutable';
import fetcher from '../api/fetcher';
import { API_BASE_URL } from '../config';
import { CatalogMeta } from '../types';

export function useCatalogsMeta(): {
  catalogsData: CatalogMeta[] | undefined;
  error: any;
  isLoading: boolean;
} {
  const { data, error } = useSWRImmutable<CatalogMeta[]>(`${API_BASE_URL}/catalog/list`, fetcher);

  return {
    catalogsData: data,
    error: error,
    isLoading: !data && !error,
  };
}
