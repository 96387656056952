import styles from './Loader.module.scss';

interface Props {
  fullPage?: boolean;
}

export function Loader({ fullPage }: Props) {
  if (fullPage) {
    return (
      <div className={styles.loaderFullPageWrapper}>
        <div className={styles.loader} role="status" />
      </div>
    );
  }

  return (
    <div className={styles.loaderWrapper}>
      <div className={styles.loader} role="status" />
    </div>
  );
}
